<template>
  <div>
    <div
      class="menu"
      v-if="
        (editor?.isActive('table') || editor?.isActive('tableCell')) && editable
      "
    >
      <keyboard-tooltip
        v-for="button in tablesButtons"
        :key="button.value"
        :key-name="button.shortcut"
        :label="button.label"
      >
        <v-btn
          :value="button.value"
          type="button"
          @click="button.action"
          small
          text
          style="min-width: 28px"
          class="px-1 ma-1"
        >
          <v-icon small>
            {{ button.icon }}
          </v-icon>
        </v-btn>
      </keyboard-tooltip>
      <v-divider />
    </div>
    <v-card-text class="px-0">
      <bubble-menu
        :editor="editor"
        :tippy-options="{ duration: 100, maxWidth: 'none' }"
        v-if="editor"
      >
        <v-card outlined>
          <v-row no-gutters>
            <v-col
              v-for="button in marksButtons"
              :key="button.value"
            >
              <keyboard-tooltip
                :key-name="button.shortcut"
                :label="button.label"
              >
                <v-btn
                  :value="button.value"
                  @click="button.action"
                  small
                  depressed
                  :color="button.active ? 'primary' : null"
                  :text="!button.active"
                  style="min-width: 28px"
                  class="px-1 ma-1"
                >
                  <v-icon small>
                    {{ button.icon }}
                  </v-icon>
                </v-btn>
              </keyboard-tooltip>
            </v-col>
            <v-divider vertical />
            <editor-menu
              :items="nodesButtons.filter((t) => t.canConvertSelection)"
              :label="$t('textEditor.buttons.turnInto')"
              icon="mdi-cached"
            />

            <template v-if="tablesButtons.length > 0">
              <v-divider vertical />

              <editor-menu
                :items="tablesButtons"
                :label="$t('textEditor.buttons.table.menuLabel')"
                icon="mdi-table"
              />
            </template>
          </v-row>
        </v-card>
      </bubble-menu>

      <floating-menu
        :editor="editor"
        :tippy-options="{
          duration: 100,
          placement: 'left',
          offset: [0, 3],
        }"
        v-if="editor"
        @click.native="editor.view.focus()"
      >
        <editor-menu
          :items="nodesButtons"
          v-model="floatingAddMenuIsOpen"
          @click.native="editor.view.focus()"
          close-on-content-click
          hide-current-active
        >
          <template #activator="{ on }">
            <v-btn
              x-small
              color="grey"
              class="pa-0"
              style="min-width: 18px; height: 18px"
              v-on="on"
              outlined
              v-tooltip="$t('textEditor.lineMenu.tooltip')"
            >
              <v-icon x-small>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
        </editor-menu>
        <v-menu
          v-model="createTableDialog"
          offset-y
          nudge-bottom="5px"
        >
          <template #activator>
            <div />
          </template>
          <v-card>
            <table-creator
              class="pa-2"
              @create-table="
                ({ rows, cols }) =>
                  editor
                    .chain()
                    .focus()
                    .insertTable({ rows, cols, withHeaderRow: true })
                    .run()
              "
            />
          </v-card>
        </v-menu>
      </floating-menu>
      <editor-content
        :editor="editor"
        class="px-7"
        :class="{
          editable: editable,
        }"
      />
    </v-card-text>
  </div>
</template>

<script>
import { BubbleMenu as BubbleMenuComponent, Editor, EditorContent, FloatingMenu } from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"
import Underline from "@tiptap/extension-underline"
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import js from "highlight.js/lib/languages/javascript"
import { common, createLowlight } from "lowlight"
import Table from "@tiptap/extension-table"
import TableCell from "@tiptap/extension-table-cell"
import TableHeader from "@tiptap/extension-table-header"
import TableRow from "@tiptap/extension-table-row"
import EditorMenu from "./editor_menu.vue"
import { Mathematics } from "@tiptap-pro/extension-mathematics"
import Placeholder from "@tiptap/extension-placeholder"
import KeyboardTooltip from "../keyboard_tooltip.vue"
import osDetection from "../../../helpers/os_detection"
import TableCreator from "./table_creator.vue"
import CharacterWordLimit from "./extensions/words_limit"
import editorButtonsMixin from "./editor_buttons_mixin"

const lowlight = createLowlight(common)
lowlight.register( { js })

export default {
  name: "TextEditor",
  mixins: [editorButtonsMixin],
  components: {
    TableCreator,
    KeyboardTooltip,
    EditorMenu,
    EditorContent,
    BubbleMenu: BubbleMenuComponent,
    FloatingMenu,
    /* eslint-disable vue/no-unused-components */
    Table, // eslint-disable-line vue/no-reserved-component-names
    TableCell,
    TableHeader,
    TableRow,
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: null,
    },
    limitType: {
      type: String,
      default: "characters",
    },
    featureTable: {
      type: Boolean,
      default: true,
    },
    featureMath: {
      type: Boolean,
      default: true,
    },
    featureCode: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    editor: null,
    createTableDialog: false,
    floatingAddMenuIsOpen: false,
  }),

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    },
  },

  computed: {
    controlKey() {
      return osDetection() === "MacOS" ? "⌘" : "Ctrl"
    },
  },

  methods: {
    currentSelectionContent() {
      const { view, state } = this.editor
      const { from, to } = view.state.selection
      return state.doc.textBetween(from, to, "")
    },
    currentCaretPosition() {
      return this.editor.view.state.selection.to
    },
    wordsCount() {
      return this.editor?.storage?.characterCount?.words()
    },
    charactersCount() {
      return this.editor?.storage?.characterCount?.characters()
    },
    emitCounters() {
      this.$emit("update-words-count", this.wordsCount())
      this.$emit("update-chars-count", this.charactersCount())
    },
  },
  mounted() {
    const that = this

    const extensions = [
      {
        enabled: true,
        object: Underline,
      },
      {
        enabled: true,
        object: CodeBlockLowlight.configure({
          lowlight,
        }),
      },
      {
        enabled: this.featureTable,
        object: Table.configure({
          resizable: true,
        }),
      },
      {
        enabled: this.featureTable,
        object: TableRow,
      },
      {
        enabled: this.featureTable,
        object: TableHeader,
      },
      {
        enabled: this.featureTable,
        object: TableCell,
      },
      {
        enabled: this.featureMath,
        object: Mathematics,
      },
      {
        enabled: true,
        object: Placeholder.configure({
          placeholder: (editor) =>
            editor?.isFocused ? null : this.placeholder,
        }),
      },
      {
        enabled: true,
        object: CharacterWordLimit.configure({
          limit: this.limit,
          limitType: this.limitType,
        }),
      },
      {
        enabled: true,
        object: StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
          listItem: {
            HTMLAttributes: {
              class: "list-disc",
            },
          },
          codeBlock: false,
        }),
      },
    ]
      .filter((extension) => extension.enabled)
      .map((extension) => extension.object)

    this.editor = new Editor({
      content: this.value,
      editable: this.editable,
      extensions: extensions,
      editorProps: {
        attributes: {
          class: "outline-none",
        },
      },
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML())
        that.emitCounters()
      },
    })

    // Emit counters on init as counters are only managed by the editor
    this.emitCounters()
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.outline-none {
  outline: 0 solid transparent;
}
.ProseMirror ul li.list-disc {
  list-style-type: disc;
}
pre > code {
  color: inherit;
  padding: 0;
  background-color: transparent !important;
  font-size: 0.8rem;
}

.ProseMirror pre {
  background: #f0f0f0;
  color: #000;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.ProseMirror table td,
.ProseMirror table th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;

  > * {
    margin-bottom: 0;
  }
}

.ProseMirror th {
  font-weight: bold;
  text-align: left;
  background-color: #f1f3f5;
}

.ProseMirror table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.ProseMirror table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}

.ProseMirror table p {
  margin: 0;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.Tiptap-mathematics-editor {
  background: #202020;
  color: #fff;
  font-family: monospace;
  padding: 0.2rem 0.5rem;
}

.Tiptap-mathematics-render {
  cursor: pointer;
  padding: 0 0.25rem;
  transition: background 0.2s;

  &:hover {
    background: #eee;
  }
}

.Tiptap-mathematics-editor,
.Tiptap-mathematics-render {
  border-radius: 0.25rem;
  display: inline-block;
}

.ProseMirror h1 {
  font-size: 2rem;
  font-weight: 700;
  margin: 1rem 0;
}
.ProseMirror h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}
.ProseMirror h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 1rem 0;
}

/*
    PM create a `p` inside each `li`. We have to remove the margin.
    https://github.com/ueberdosis/tiptap/issues/118#issuecomment-1210705173
  */
.ProseMirror li > p {
  margin: 0;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.editable .ProseMirror {
  min-height: 75px;
}

/*
  Fix for  floating toolbar not displayed in production only.
  Borrowed from https://github.com/ueberdosis/tiptap/issues/4851
*/
.tippy-content div {
  visibility: visible !important;
}
</style>
